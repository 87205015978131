<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="xl"
      class="alert-modal"
      hide-header-close
      content-class="modal-register"
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="d-flex justify-content-center w-100 align-items-center">
          <div class="modal-title">เพิ่มที่อยู่ใบกำกับภาษี</div>
        </div>
      </template>
      <div class="d-block">
        <!-- :v="$v" -->
        <BillingForm
          ref="BillingForm"
          :form="form"
          :id="formId"
          @success="hide"
          :fields="fields"
          :dynamicField="dynamicField"
          @AddOptionalTelephone="AddOptionalTelephone"
        />
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import BillingForm from "./BillingForm.vue";

import { mapGetters } from "vuex";
import { billingField, mapBillingField } from "@/assets/json/fieldAddress.js";
export default {
  components: {
    InputText,
    InputDatePickerFilter,
    InputRadioGroup,
    BillingForm,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        prefix: null,
        subdistrict: null,
        district: null,
        province: null,
        email: "",
        country: null,
        custom_field: [],
        birthday: null,
        is_consent: 0,
      },
      now: null,
      fields: [],
      dynamicField: [],
      isAddOptionalTelephone: false,
      // limitOptionalTel: null,
      telList: [],
      formId: 0,
    };
  },

  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  computed: {
    ...mapGetters({ limitOptionalTel: "getLimitTelephone" }),
  },

  watch: {
    telList: {
      handler(newVal) {
        newVal.forEach((item) => {
          if (!item.tel) {
            item.ext = "";
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    checkIsProfile(val) {
      const findField = mapBillingField.find((el) => el.id == val);

      return findField ? findField.key : false;
    },
    async show(id) {
      this.form = {
        prefix: null,
        subdistrict: null,
        district: null,
        province: null,
        email: "",
        country: null,
        custom_field: [],
        gender: null,
        birthday: null,
        is_consent: 0,
      };
      this.formId = id;
      this.isAddOptionalTelephone = false;
      (this.telList = []), this.telList.push({ tel: "", ext: "" });

      this.showModal = true;
    },
    async getField() {
      const data = await this.$axios.get(
        `${this.$baseUrl}/customer/GetBillingForm`
      );

      if (data.result === 1) {
        this.fields = data.detail.profileField.filter((el) => el.is_show == 1);

        this.dynamicField = data.detail.customField.filter(
          (el) => el.is_show == 1
        );

        for (const field of data.detail.customField) {
          if (field.field_type_id == 2) {
            field.user_answer = [];
          } else {
            field.user_answer = "";
          }
        }
      }
    },
    addOptinalTel() {
      if (
        !this.limitOptionalTel ||
        this.telList.length < this.limitOptionalTel
      ) {
        this.telList.push({ tel: "", ext: "" });
      }
    },
    hide() {
      (this.telList = []), (this.showModal = false);
    },
    AddOptionalTelephone() {
      this.isAddOptionalTelephone = true;
    },
    closeOptionalPhone() {
      this.telList = [];
      this.telList.push({ tel: "", ext: "" });
      this.$v.$reset();
      this.isAddOptionalTelephone = false;
    },
    saveOptionalPhone() {
      this.$v.telList.$touch();
      if (!this.$v.$error) {
        this.telList = this.telList.filter((item) => item.tel.trim() !== "");

        if (this.telList.length === 0) {
          this.telList.push({ tel: "", ext: "" });
        }
        this.form.optional_telephone = this.telList
          .map((item) => (item.ext ? `${item.tel}-${item.ext}` : item.tel))
          .join(",");
        this.isAddOptionalTelephone = false;
      }
    },
    deleteTel(index) {
      if (index == 0) {
        this.telList[0].tel = "";
        this.telList[0].ext = "";
        return;
      }
      this.telList.splice(index, 1);
    },
    async save() {
      const res = await this.$refs.BillingForm.submit();
      if (res) this.$emit("getNewList");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .modal-register {
  .btn-link {
    border-color: transparent !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    padding: 0;
    color: #f7f7f7 !important;
  }

  .icon-delete {
    width: 20px;
    height: 20px;
  }
  .register-box {
    background: #f7f7f7;
    border: 1px solid #d8dbe0;

    max-height: 500px;
    overflow: auto;
  }
  .nav-tabs {
    .nav-item {
      margin-bottom: 0px;
    }
    .nav-link {
      border: 1px solid white !important;
      border-bottom: 3px solid white !important;
      font-weight: 600;
      background: white !important;
      color: #333 !important;
    }
    .nav-link.active {
      border: 1px solid var(--secondary-color) !important;
      border-bottom: 3px solid var(--primary-color) !important;
      color: var(--primary-color) !important;
    }
  }
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}
.text-link {
  right: 0;
  position: absolute;
  margin-right: 20px;
}
</style>
