<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">
          กรุณากรอกข้อมูลเพื่อยกเลิกรายการ {{ transaction.invoice_no }}
        </div>
      </template>
      <div class="d-flex justify-content-center py-3">
        <div class="d-block w-75">
          <InputText
            v-model="form.managerId"
            textFloat="Manager ID:"
            className="mb-2"
            isRequired
            ref="managerId"
            @onEnter="handleEnter"
            :isValidate="$v.form.managerId.$error"
            :v="$v.form.managerId"
          />
          <b-form-checkbox 
          v-if="!isVoid"
            class="title mt-3"
            v-model="form.isReturnInventory"
            :value="1"
            @input="handleReturnInventory"
            :unchecked-value="0"
            :disabled="transaction.is_top_up_credit"
          >
            Inventory
          </b-form-checkbox>

          <!-- <b-form-checkbox
            v-if="void_return_to_store_credit"
            class="title mt-3"
            v-model="form.is_return_store_credit"
            :disabled="!transaction.is_store_credit"
            :value="true"
            :unchecked-value="false"
          >
            Store Credit
          </b-form-checkbox> -->
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="cancelTransaction"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required, minValue, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
    isVoid:{
      required: false,
      type: Boolean,
    }
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      void_return_to_store_credit: "getIsVoidCredit",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        managerId: "",
        isReturnInventory: 1,
        // is_return_store_credit: true,
      },
      transaction: null,
    };
  },
  validations() {
    return {
      form: {
        managerId: {
          required,
        },
      },
    };
  },
  watch: {
    // "form.isReturnInventory": {
    //   handler(val) {
    //     if (val == 0 && this.showModal) {
    //       this.warningAlert("Product/Serial จะไม่ถูกคืนกลับสู่ Inventory", "คำเตือน", "ยืนยัน");
    //     }
    //   },
    // },
  },
  methods: {
    async show(transaction) {
      this.transaction = { ...transaction };
      this.form = {
        managerId: "",
        isReturnInventory:
          this.transaction.is_top_up_credit ||
          (transaction.user_transaction_status_id &&
            transaction.user_transaction_ref_id)
            ? 0
            : 1,
        // is_return_store_credit: this.transaction
        //   ? this.transaction.is_store_credit
        //   : false,
      };
      this.$v.form.$reset();
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    handleReturnInventory(val) {
      if (!val)
        this.warningAlert(
          "Product/Serial จะไม่ถูกคืนกลับสู่ Inventory",
          "คำเตือน",
          "ยืนยัน"
        );
    },
    async cancelTransaction() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      await this.$store.dispatch("setMainLoading", true);
      await this.axios
        .get(
          `${this.$baseUrl}/transaction/delete_transaction_data/${this.transaction.id}/${this.form.managerId}/${this.branchId}/${this.transaction.isSpa}/${this.isVoid ? 1 :this.form.isReturnInventory}`
        )
        .then((data) => {
          this.isLoading = false;
          this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert(data.message).then(() => {
              this.$router.push("/transaction");
            });

            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    handleEnter() {
      const inputElement = this.$refs.managerId.$el.querySelector("input");
      if (inputElement) {
        inputElement.blur();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}

::v-deep .modal-body {
  padding: 1rem 1rem;

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
}

.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}

.panel-display-count {
  padding-top: 10px;

  p.label {
    color: #333 !important;
    font-weight: bold !important;
  }

  p {
    color: #999 !important;
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  ::v-deep .modal-title {
    text-align: center;
  }
}
</style>
